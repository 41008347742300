<template>
    <div class="menus">
       <div  class="menu-form">
            <el-select v-model="category" placeholder="分类" clearable>
                <el-option v-for="item in categories" :label="item" :key="item" :value="item"></el-option>
            </el-select>
            <el-button  type="primary" @click="addRootMenu" style="margin-left: 24px">添加根菜单 </el-button>
     </div>


        <el-row :gutter="20">
            <el-col :span="24">

                <div class="menu-tree">
                    <el-tree
                        :data="menus"
                        :render-content="renderContent"
                        :highlight-current="true"
                        :expand-on-click-node="true"
                        node-key="id"
                        v-loading="loading"
                        accordion
                        @node-click="nodeClick"
                        :default-expanded-keys="expandKeys"
                        :default-checked-keys="expandKeys"
                    >
                    </el-tree>


                </div>
            </el-col>
        </el-row>


        <!-- update -->
        <el-dialog :visible.sync="dialogVisible"  :title=" menu.id ? '编辑菜单' : '新增菜单' ">
            <div class="menu-edit">
                <el-form :model="menu" ref="form" label-position="top">
                    <el-form-item
                        label="父级菜单"
                        prop="parent"
                    >
                        <el-select v-model="menu.parent"
                            :clearable="true"
                            :filterable="true"
                            placeholder="请选择父级菜单">
                            <el-option v-for="(item,index) in menus" :key="index" :label="item.name" :value="item.id"  >
                                {{item.name}} # <b>{{item.id}}</b>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="菜单名"
                        prop="name"
                        :rules="[{ required: true, message: '请填写菜单名', trigger: 'blur' }]"
                    >
                        <el-input v-model="menu.name"></el-input>
                    </el-form-item>
                    <el-form-item label="菜单地址" prop="path">
                        <el-select v-model="menu.path" :clearable="true" :filterable="true" class="select-ext">
                            <el-option v-for="(item,index) in pathOptions" :label="item.path" :value="item.path">
                                    {{item.path}} # <b>{{item.meta && item.meta.title}}</b>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="icon">
                        <template v-slot:label
                            >图标
                           <!-- <a
                                href="https://fontawesome.com/icons?d=gallery&s=brands,solid&m=free"
                                target="_blank"
                                class="available-icons"
                                >查看所有可用图标</a
                            > -->


                        </template>
                        <el-input v-model="menu.icon">
                            <template v-slot:prepend >
                                <span ref="menuIcon" style="font-size: 18px;display:inline-block;width:30px;">
                                    <i :class="menu.icon"></i>
                                </span>
                            </template>

                            <template v-slot:append>
                                <el-popover
                                    width="400"
                                    title="图标选择"

                                >
                                    <AllIcon  :value.sync="menu.icon" ></AllIcon>
                                    <el-button size="mini" type="success" slot="reference"  >选择图标</el-button>
                                </el-popover>
                            </template>

                        </el-input>

                    </el-form-item>
                    <el-form-item prop="category" label="分类" v-if="menu.root">
                        <el-select v-model="menu.category" :clearable="true" class="select-ext">
                            <el-option v-for="(item,index) in categoryOptions" :key="index" :label="item" :value="item">
                                {{item}}
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="authorities" label="权限">
                        <el-select
                            v-model="menu.authorities"
                            clearable
                            multiple
                            value-key="name"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in authorities"
                                :label="item.description"
                                :value="item"
                                :key="item.name"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

            </div>

            <div slot="footer">
                <el-button @click="dialogVisible = false">取消 </el-button>
                <el-button type="primary" @click="addMenu" :loading="loading">保存 </el-button>
            </div>

        </el-dialog>
    </div>
</template>
<script>

import AllIcon from "@/components/allIcon.vue";

export default {
    components:{
        AllIcon
    },
    created() {
        this.getData();
        this.$http.get('/authority/all').then(res => {
            //this.authorities = res.filter(i => i.name === 'ROLE_ADMIN' || i.name === 'ROLE_OPERATOR');
            this.authorities = res || [];
        });
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            menus: [],
            menu: {
                name: '',
                path: '',
                icon: '',
                root: false,
                active: true
            },
            categories: [],
            category: null,
            expandKeys: [],
            authorities: [],
            categoryOptions: [
                "用户",
                "系统",
                "开发"
            ]
        };
    },
    methods: {
        addRootMenu() {
            this.menu = {
                name: '',
                path: '',
                active: true,
                root: true,
                icon: 'bars',
                category: this.category || null,
                authorities: [{ name: 'ROLE_ADMIN' }]
            };
            this.dialogVisible = true;
        },
        showAddDialog(node, data) {
            this.menu = {
                parent: node.data.id,
                name: '',
                path: '',
                active: true,
                root: false,
                icon: null,
                authorities: [{ name: 'ROLE_ADMIN' }]
            };
            this.dialogVisible = true;
        },
        showEditDialog(node, data) {
            const getIconName = icon => {
                let iconName = '';
                if (icon) {
                    iconName = icon.replace('fas ', '').replace('fab ', '').replace('fa-', '');
                }
                return iconName || null;
            };
            let iconName = getIconName(data.icon);
            this.menu = {
                ...data
            };
            this.dialogVisible = true;
        },
        addMenu() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    let menu = { ...this.menu };
                    delete menu.children;
                    if(menu.parent && menu.parent != 0){
                        menu.root = false;
                    }else{
                        menu.root = true;
                    }

                    this.$http
                        .post('/menu/save', menu, { body: 'json' })
                        .then(res => {
                            this.loading = false;
                            this.$message.success('添加成功');
                            this.dialogVisible = false;
                            this.getData();
                        })
                        .catch(e => {
                            console.log(e);
                            this.loading = false;
                            this.$message.error(e.error);
                        });
                }
            });
        },
        remove(node, data) {
            console.log(node);
            this.$confirm('确定删除菜单‘'+data.name+'’？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(() => {
                    return this.$http.post(
                        '/menu/save',
                        {
                            ...data,
                            active: false,
                            children: null
                        },
                        { body: 'json' }
                    );
                })
                .then(res => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    this.loading = false;
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        },
        moveUp(node, data) {
            if (node.previousSibling) {
                this.loading = true;
                let sort0 = node.previousSibling.data.sort,
                    sort1 = node.data.sort;
                Promise.all([
                    this.$http.post(
                        '/menu/save',
                        {
                            ...node.data,
                            children: null,
                            sort: sort0
                        },
                        { body: 'json' }
                    ),
                    this.$http.post(
                        '/menu/save',
                        {
                            ...node.previousSibling.data,
                            children: null,
                            sort: sort1
                        },
                        { body: 'json' }
                    )
                ])
                    .then(_ => {
                        this.loading = false;
                        this.getData();
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                        this.$message.error(e.error);
                    });
            }
        },
        moveDown(node, data) {
            if (node.nextSibling) {
                this.loading = true;
                let sort0 = node.data.sort,
                    sort1 = node.nextSibling.data.sort;
                Promise.all([
                    this.$http.post(
                        '/menu/save',
                        {
                            ...node.data,
                            children: null,
                            sort: sort1
                        },
                        { body: 'json' }
                    ),
                    this.$http.post(
                        '/menu/save',
                        {
                            ...node.nextSibling.data,
                            children: null,
                            sort: sort0
                        },
                        { body: 'json' }
                    )
                ])
                    .then(_ => {
                        this.loading = false;
                        this.getData();
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                        this.$message.error(e.error);
                    });
            }
        },
        getData() {
            this.loading = true;
            this.$http
                .get('/menu/all', { category: this.category })
                .then(res => {
                    this.menus = res;
                    this.$nextTick(() => {
                        FontAwesome.dom.i2svg();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                }).finally(() => {
                    this.loading = false;
                });

            this.$http.get('/menu/categories').then(res => {
                this.categories = res;
            });
        },
        renderContent(h, { node, data, store }) {
            return (
                <span
                    class={
                        this.menu.id == data.id || (this.menu.parent == data.id && !this.menu.id)
                            ? 'custom-tree-node selected'
                            : 'custom-tree-node'
                    }
                >
                    <span class="menu-nu">{data.sort}</span>
                    <span  class="menu-icon"> <i class={data.icon} ></i> </span>
                    <span>{data.name}</span>
                    <span class="url">{data.path}</span>
                    <span class="opt">
                        <el-button
                            type="text"
                            on-click={e => {
                                this.moveUp(node, data), e.stopPropagation();
                            }}
                            class="up"
                            icon="el-icon-top"
                        >
                            上移
                        </el-button>
                        <el-button
                            type="text"
                            on-click={e => {
                                this.moveDown(node, data), e.stopPropagation();
                            }}
                            icon="el-icon-bottom"
                        >
                            下移
                        </el-button>
                        <el-button
                            type="text"
                            on-click={e => {
                                this.showEditDialog(node, data), e.stopPropagation();
                            }}
                            icon="el-icon-edit"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            on-click={e => {
                                this.showAddDialog(node, data), e.stopPropagation();
                            }}
                            icon="el-icon-plus"
                            style={{display: data.root ? 'block' : 'none'}}
                        >
                            添加
                        </el-button>
                        <el-button
                            type="text"
                            on-click={e => {
                                this.remove(node, data), e.stopPropagation();
                            }}
                            icon="el-icon-delete"
                        >
                            删除
                        </el-button>
                    </span>
                </span>
            );
        },
        nodeClick(data, node, el) {
            if (this.expandKeys[0] != data.id) {
                this.expandKeys = [data.id];
            }
        },
    },
    computed:{
      pathOptions(){
          let routes =  this.$router.getRoutes();
          routes = routes.filter((item) => {
             return  item.path && item.path != "*";
          })
          return routes;
        }
    },
    watch: {
        category() {
            this.getData();
        },
        "menu.icon":{
            handler(val){
                if(this.$refs.menuIcon){
                    this.$refs.menuIcon.innerHTML = "<i class='"+val+"'></i>";
                    FontAwesome.dom.i2svg();
                }
            }
        }
    }
};
</script>
<style lang="less" scoped>
.menus {
    padding: 20px;
}

.menu-form{
    display:flex;
    margin-bottom: 30px;
}



.menu-tree {
    border-radius: 4px;
    background: white;
    padding: 10px;
}

.menu-edit{
    background: white;
}

.select-ext{
    width: 100%;
}

/deep/ .menu-nu{
    color: #999;
    width: 40px;
    font-size: 12px;
}

/deep/ .menu-icon{
    width: 40px;
    text-align: center;
}




/deep/ .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    line-height: 40px;
    height: 40px;
    .url {
        flex-grow: 1;
        text-align: right;
        margin-right: 20px;
        color: #999;
    }
    .opt {
        padding:0 30px;
        display: flex;
    }

    &.selected {
        border: 2px solid @prim;
        border-radius: 4px;
        padding: 0 10px;
        box-sizing: border-box;

    }

}
/deep/ .menu-tree {
    .el-tree-node__content {
        height: 40px;
        line-height: 40px;
    }
}

/deep/ .available-icons {
    color: @prim;
    text-decoration: none;
    &:hover {
        color: @prim;
        text-decoration: none;
    }
}
</style>
